var tarteaucitronForceLanguage = 'fr';

var siteUrl = document.location.protocol + '//' + document.location.host;

tarteaucitron.init({
    "privacyUrl": siteUrl + "/content/7-donnees-personnelles-et-cookies",
    "hashtag": "#cookies",
    "highPrivacy": true,
    "orientation": "overlay",
    "adblocker": false,
    "showAlertSmall": false,
    "cookieslist": true,
    "image": siteUrl + "/themes/amsterdamair2021/assets/dest/img/logo.svg",
    "backgroundImage": "",
    "removeCredit": false,
    "AcceptAllCta": true,
    "DenyAllCta": true,
    "useExternalCss": true,
    "groupServices": false,
    "googleConsentMode": true
});

// tarteaucitron.user.analyticsUa = 'UA-2056568-1';
// tarteaucitron.user.analyticsAnonymizeIp = true;
// tarteaucitron.user.analyticsMore = function () { /* add here your optionnal ga.push() */ };
// (tarteaucitron.job = tarteaucitron.job || []).push('analytics');

// Google Tag Manager
tarteaucitron.user.googletagmanagerId = 'GTM-TL745CH';
(tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');

//gmap
// prod
// tarteaucitron.user.googlemapsKey = 'AIzaSyAWKRqKjMAnMBCQd-PPN4cyIpSMBrrAa4I';
// preprod
tarteaucitron.user.googlemapsKey = 'AIzaSyDKQKxJSrEP040BuYxhYP-2wefX8Ue6HJw';
tarteaucitron.user.mapscallback = 'initializeGmaps';
(tarteaucitron.job = tarteaucitron.job || []).push('googlemaps');
// recaptcha
tarteaucitron.services.recaptchaCustom = {
    "key": "recaptchaCustom",
    "type": "api",
    "name": "reCAPTCHA",
    "uri": "http://www.google.com/policies/privacy/",
    "needConsent": true,
    "cookies": ['nid'],
    "js": function () {
        "use strict";
        tarteaucitron.addScript('https://www.google.com/recaptcha/api.js?hl='+tarteaucitronForceLanguage);
        tarteaucitron.fallback(['tacReCAPTCHA'], '');
    },
    "fallback": function () {
        "use strict";
        var id = 'recaptchaCustom';
        tarteaucitron.fallback(['tacReCAPTCHA'], engage(tarteaucitron.engage(id)));
    }
};

function engage(html) {
    return html + '<input type="hidden" name="g-recaptcha-response" value="" />';
}
(tarteaucitron.job = tarteaucitron.job || []).push('recaptchaCustom');
